import { Route, Redirect } from "react-router-dom";
import React, {Suspense, lazy, Fragment,useState} from 'react';

import {
    ToastContainer,
} from 'react-toastify';
// import searchOpportunity from '../../DemoPages/SearchOpportunity/index'

import axios from "axios";

import {
    toast,
    Bounce,
  } from 'react-toastify';
  
  import 'react-toastify/dist/ReactToastify.css';


const Homepage   = lazy(() => import('../../DemoPages/Homepage'));

const LoginPage  = lazy(() => import('../../DemoPages/Auth'));

const SignUpPage  = lazy(() => import('../../DemoPages/Auth'));

const ForgotPassword = lazy(() => import('../../DemoPages/Auth'))

// contact page

const ContactPage = lazy(()=> import('../../DemoPages/ContactPage'));

// const userRoles = lazy(() => import('../../DemoPages/UserRoles'));

const AboutPage = lazy(() => import('../../DemoPages/AboutPage'))

// MANAGE USER ROLES

const ManageUserRoles = lazy(() => import('../../DemoPages/AdminUsers'))

const AdminOpportunitiesTable = lazy(() => import('../../DemoPages/AdminOppoTable'))


// SEARCH OPPORTUNITY

const SearchOpportunity = lazy(() => import('../../DemoPages/SearchOpportunity'));

// USer Profiles Table 

// const UserProfiles = lazy(() => import('../../DemoPages/UserProfilesTable'));

const CitizenDashboard = lazy(() => import('../../DemoPages/CitizenAdmin'));


// Citizen Personal Profile Component
const CreatePersonalProfile = lazy(() => import('../../DemoPages/PersonalProfilePages'));

// auth page

// const AuthPage = lazy(() => import("../../DemoPages/AuthPage"));

const Official = lazy(()=> import('../../DemoPages/AdminPages'));

// Business Profile components
const CreateBusinessProfile = lazy(() => import('../../DemoPages/BusinessProfilePages'));


// privacyPolicy FOrm
const PrivacyPolicy = lazy(()=> import('../../DemoPages/SearchOpportunity/forms/app'));

const MailBox  = lazy(() => import('../../DemoPages/MailBox/'))



const AdminDashboard = lazy(() => import('../../DemoPages/Dashboards'))

const Widgets = lazy(() => import('../../DemoPages/Widgets'));
const AdminWelcomePage = lazy(()=> import("../../DemoPages/AdminWelcomePage"))

// const Elements = lazy(() => import('../../DemoPages/Elements'));
// const Components = lazy(() => import('../../DemoPages/Components'));
const Charts = lazy(() => import('../../DemoPages/Charts'));

const Tables = lazy(() => import('../../DemoPages/Tables'));


function clearSessionStorageData(){
    sessionStorage.removeItem("AccountID");
     sessionStorage.removeItem("Username");
     sessionStorage.removeItem("First Name");
     sessionStorage.removeItem("Last Name");
     sessionStorage.removeItem("Role");

     sessionStorage.removeItem("profile");
     sessionStorage.removeItem("workInfo");
     sessionStorage.removeItem("skillsInfo");
     sessionStorage.removeItem("educationInfo");
     sessionStorage.removeItem("refInfo");
     sessionStorage.removeItem("cv");

     sessionStorage.removeItem('bpID');
     sessionStorage.removeItem("business");
     
     sessionStorage.removeItem("token");
     sessionStorage.setItem('in', 0);
}

function clearBusinessCityNames(){

sessionStorage.removeItem("total_amielpark");

 sessionStorage.removeItem("total_ambleside") ;


sessionStorage.removeItem("total_amitikulu" ) ;

sessionStorage.removeItem("total_arborville") ;

sessionStorage.removeItem("total_arborpark") ;

sessionStorage.removeItem("total_arboretum") ;

sessionStorage.removeItem("total_appelsbosch") ;

sessionStorage.removeItem("total_appelbos") ;

sessionStorage.removeItem("total_anerley") ;

sessionStorage.removeItem("total_ballitoville") ;

sessionStorage.removeItem("total_dundee") ;

sessionStorage.removeItem("total_durban") ;

sessionStorage.removeItem("total_eshowe") ;

sessionStorage.removeItem("total_ekuvukeni") ;

sessionStorage.removeItem("total_empangeni") ;

sessionStorage.removeItem("total_greytown") ;

sessionStorage.removeItem("total_glencoe") ;

sessionStorage.removeItem("total_mondlo") ;

sessionStorage.removeItem("total_mooi_river") ;

sessionStorage.removeItem("total_mpophomeni") ;

sessionStorage.removeItem("total_newcastle") ;

sessionStorage.removeItem("total_kokstad") ;

sessionStorage.removeItem("total_howick") ;

sessionStorage.removeItem("total_pietermaritzburg") ;

sessionStorage.removeItem("total_portShepstone") ;

sessionStorage.removeItem("total_richardsBay") ;

sessionStorage.removeItem("total_richmond") ;


sessionStorage.removeItem("total_ulundi") ;

sessionStorage.removeItem("total_scottsburgh") ;

sessionStorage.removeItem("total_sundumbili") ;

sessionStorage.removeItem("total_stanger") ;

sessionStorage.removeItem("total_umkomaas") ;

sessionStorage.removeItem("total_utrecht") ;

sessionStorage.removeItem("total_vryheid") ;

sessionStorage.removeItem("total_margate") ;


}



function refreshPage() { window.location.reload(false); }


 // alert Info alert
 const logoutMessage  = () =>  toast("You have Logged out!", {
    transition: Bounce,
    closeButton: true,
    autoClose: 2000,
    position: 'top-center',
    type: 'info'
 },

//  remove session storage data

clearSessionStorageData(),

// set login state to false
 sessionStorage.setItem('login', false),

//  clear clearBusinessCityNames

clearBusinessCityNames()
 
 );

 

const AppMain = () => {

    const test = sessionStorage.getItem('login')
       

    // function checkAuth(){
    //     if(test){
    //         setLoggedIN(true);
    //     }else{
    //         setLoggedIN(false)
    //     }
    // }
  
    const test2 = parseInt(sessionStorage.getItem('in'))

    const oppoCheck = parseInt(sessionStorage.getItem('oppo'))

    const [isLoggedIn,setLoggedIN] = useState(true)

 
      




    return (
        <Fragment>
            
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            Please wait while we load the Contact Page
                            {/* <small>Because this is a demonstration we load at once all the Forms examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
                
                <Route path="/contact" component={ContactPage}/>
            </Suspense>


            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            Please wait while we load the About Page
                            {/* <small>Because this is a demonstration we load at once all the Forms examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/about" component={AboutPage}/>
            </Suspense>

            {/* USer profiles */}
          

            {/* Charts */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load all the Charts 
                            {/* <small>Because this is a demonstration we load at once all the Charts examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
                
               

                {test2 === 1 ?    <Route path="/charts" component={Charts}/>
                 :    <Route exact path="/charts" render={() => (
                 
                    //  {logoutMessage()}
                    <Redirect to="/homepage"/>
               
                )}/>}
            </Suspense>

            {/* Tables */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            Please wait while we load all the Tables examples
                            <small>Because this is a demonstration we load at once all the Tables examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/tables" component={Tables}/>
            </Suspense>



            {/* Dashboard Welcome page */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load the page.
                            {/* <small>Because this is a demonstration we load at once all the Dashboard Widgets examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
               

                {test2 === 1 ?   <Route path="/WelcomePage" component={Widgets}/>
                 :    <Route exact path="/WelcomePage" render={() => (
                 
                    //  {logoutMessage()}
                    <Redirect to="/homepage"/>
               
                )}/>}
            </Suspense>


                        <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load the page.
                            {/* <small>Because this is a demonstration we load at once all the Dashboard Widgets examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
                

                {test2 === 1 ?  <Route path="/adminWelcomePage" component={AdminWelcomePage}/>
                 :    <Route exact path="/adminWelcomePage" render={() => (
                 
                    //  {logoutMessage()}
                    <Redirect to="/homepage"/>
               
                )}/>}
            </Suspense>


            {/* Dashboards */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load the Admin Dashboard Page
                            {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                        
                    </div>
                </div>
            }>
               
               {test2 === 1 ?   <Route path="/adminDashboard" component={AdminDashboard}/> 
                 :    <Route exact path="/adminDashboard" render={() => (
                 
                    //  {logoutMessage()}
                    <Redirect to="/homepage"/>
               
                )}/>}
            
            
            </Suspense>

                    {/* Citizen ADMIN */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                        Please wait while we load the Citizen Dashboard
                            {/* <small>Because this is a demonstration, we load at once all the  examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
  
              {test2 === 1 ?  <Route path="/citizenDashboard" component={CitizenDashboard} />
               :    <Route exact path="/citizenDashboard" render={() => (
                 
                 
                 <Redirect to="/homepage"/>
            
             )}/>}


         

{/* {refreshPage()} */}
            
            </Suspense>

            

              {/* MANAGE USER ROELES */}
              <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            
                            Please wait while we load the Page
                            {/* <small>Because this is a demonstration, we load at once all the  examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/users" component={ManageUserRoles}/>
            </Suspense>

            

                {/* MANAGE Opportunities */}
                <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            
                            Please wait while we load  the Page
                            {/* <small>Because this is a demonstration, we load at once all the  examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
                           {test2 === 1 ?    <Route path="/opportunityTable" component={AdminOpportunitiesTable}/>
                             :    <Route exact path="/opportunityTable" render={() => (
                 
                                //  {logoutMessage()}
                                <Redirect to="/homepage"/>
                           
                            )}/>}    
          </Suspense>
           

             {/* PERSONAL PROFILE */}
            
         <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                        
                            Please wait while we load your mail inbox
                            {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
                {test2 === 1 ? <Route path="/myMailBox" component={MailBox}/>
                 :    <Route exact path="/myMailBox" render={() => (
                 
                    //  {logoutMessage()}
                    <Redirect to="/homepage"/>
               
                )}/>} 
            </Suspense>



        


            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load the Homepage
                            {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                          
                    </div>
                  
                </div>
            }>
                <Route path="/homepage" component={Homepage}/>
            </Suspense>

              

                      {/* SIGN UP PAGE */}
                      <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load the SignUp page
                            {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/signup" component={SignUpPage}/>
            </Suspense>

                {/* LOGIN PAGE */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load the Login Page
                            {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
               
                <Route path="/login" component={LoginPage}/>
            </Suspense>

                            {/* Register PAGE */}
                            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load the Register Page
                            {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/register" component={LoginPage}/>
            </Suspense>

                             {/* Register PAGE */}
                             <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load the Forgot Password Page
                            {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/forgotPassword" component={ForgotPassword}/>
            </Suspense>



   {/* Vacancy */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load the pwdmobile Privacy Policy
                            {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/pwdmobile_Privacy_Policy" component={PrivacyPolicy}/>
            </Suspense>

            {/* PERSONAL PROFILE SCREEN */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load Create Personal Profile Page
                            {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
                        </h6>

                        {/* <LoadingPageSpinner  /> */}
                    </div>
                </div>
            }>
               {test2 === 1 ?   <Route path="/createMyPersonalProfile" component={CreatePersonalProfile}/>
            :    <Route exact path="/createMyPersonalProfile" render={() => (
                 
                //  {logoutMessage()}
                <Redirect to="/homepage"/>
           
            )}/>}
           
           </Suspense>

                    {/* Businness PROFILE SCREEN */}

                    <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load Crate Business Profile page
                            {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
              {test2 === 1 ?   <Route path="/createMyBusinessProfile" component={CreateBusinessProfile}/>
             :    <Route exact path="/createMyBusinessProfile" render={() => (
                 
                //  {logoutMessage()}
                <Redirect to="/homepage"/>
           
            )}/>}
           </Suspense>

            {/* Official */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                   
                        Please wait while we load the Upload Opportunity Page
                            {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
              {test2 === 1 ?   <Route path="/addOpportunity" component={Official}/>
             :    <Route exact path="/addOpportunity" render={() => (
                 
                //  {logoutMessage()}
                <Redirect to="/homepage"/>
           
            )}/>} 
           
           </Suspense>


                        {/* CITIZEN SEARCH OPPORTUNITY */}
                        


                        <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                      
                        Please wait while we load the Search Opportunities Page
                            {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
                        </h6>
                    </div>
                </div>
            }>
              {test2 === 1 ?    <Route path="/searchOpportunity" component={SearchOpportunity}/>
             :    <Route exact path="/searchOpportunity" render={() => (
                 
                //  {logoutMessage()}
                <Redirect to="/homepage"/>
           
            )}/>} 
            
            </Suspense>

                {/* Mail Box */}
       

            {/* Cahrts */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load all the Charts examples
                            <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/analyticsCharts" component={Charts}/>
            </Suspense>

            {/* <Route exact path="/" render={() => (
                <Redirect to="/dashboards/basic"/>
            )}/> */}
            {/* FIRT LANDING PAGE */}
            <Route exact path="/" render={() => (
                 
                //  {logoutMessage()}
                <Redirect to="/homepage"/>
           
            )}/>


{/* 
{isLoggedIn === false ? <Route exact path="/citizenDashboard" render={() => (
                 
                 //  {logoutMessage()}
                 <Redirect to="/"/>
            
             )}/>
             :    <Redirect to="/login"/>
}

{isLoggedIn === false ? <Route exact path="/adminDashboard" render={() => (
                 
                 //  {logoutMessage()}
                 <Redirect to="/"/>
            
             )}/>
             :    <Redirect to="/login"/>
} */}


<Route exact path="/logout" render={() => (

<Suspense fallback={
    <div className="loader-container">
        <div className="loader-container-inner">
            <h6 className="mt-3">
                Logging out!
                {/* <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small> */}
            </h6>
        </div>
    </div>
}>
                 
                  {logoutMessage()}
   

                 <Redirect to="/homepage"/>
                 </Suspense>
             )}/>

       
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;